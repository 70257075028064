import { styled } from "@mui/material"
import { TreeNode } from "@tm/models"
import { getUIA } from "@tm/utils"
import { TreeCategory } from "./TreeCategory"

const OverviewGrid = styled("div", { shouldForwardProp: (prop) => prop !== "gridTemplateColumns" })<{ gridTemplateColumns: string }>(
    ({ gridTemplateColumns }) => ({
        display: "grid",
        columnGap: "1em",
        rowGap: "1em",
        padding: "1em 1em 0.5em 1em",
        gridTemplateColumns,
    })
)

type Props = {
    initialTree: TreeNode[]
    hideSecondLevel?: boolean
    variant: "default" | "small"
    nodeVariant?: "fastClick" | "default"
    onNodeClick: (node: TreeNode) => void
}

export function SearchTreeOverview(props: Props) {
    const gridTemplateColumns =
        props.variant === "small" ? "repeat(2, minmax(0, 1fr))" : props.nodeVariant === "fastClick" ? "1fr 1fr 1fr 1fr" : "repeat(3, minmax(0, 1fr))"
    return (
        <OverviewGrid {...getUIA("SearchtreeV2Overviewgrid")} gridTemplateColumns={gridTemplateColumns} className="overview-grid">
            {props.initialTree.map((node) => {
                return (
                    <TreeCategory
                        key={`nodeKey_${node.id}`}
                        category={node}
                        onNodeClick={props.onNodeClick}
                        hideSecondLevel={props.hideSecondLevel}
                        nodeVariant={props.nodeVariant}
                    />
                )
            })}
        </OverviewGrid>
    )
}

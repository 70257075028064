import { Box, Stack, Tooltip, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { DMS, DmsArticleInfo } from "@tm/models"
import { Icon } from "../../../generics/Icons"
import { Button } from "../../../generics/button"
import { AvailabilityIcon, InfoList } from "./components"

type Props = {
    dmsArticleInfo: DmsArticleInfo
}

const StyledStack = styled(Stack)(({ theme }) => ({
    padding: "0.25em 0.75em",
    alignItems: "center",
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: theme.radius?.default || "3px",
}))

const StyledTooltip = styled(Tooltip)({
    textAlign: "center",
    whiteSpace: "pre-wrap",
})

const StyledIcon = styled(Icon)({
    marginLeft: "0.5em",
    marginRight: "0.5em",
    height: "22px",
    width: "22px",
})

export function ArticleInfo({ dmsArticleInfo }: Props) {
    const { stockItem, voucherInfoItems, stockInfoItems, permittedOperations, handleVoucherClick } = dmsArticleInfo
    const { translateText } = useLocalization()

    if (!stockItem || (!stockItem.invoiced && (stockItem.availability === undefined || stockItem.availability === DMS.Availability.Undefined))) {
        return null
    }

    const renderStatus = () => {
        const tooltip = []

        if (stockItem.invoiced) {
            tooltip.push(translateText(1985))
        }

        if (stockItem.availability === DMS.Availability.Available) {
            tooltip.push(translateText(1986))
        }

        return (
            <StyledTooltip title={tooltip.join("\n\n")}>
                <Box display="flex">
                    <AvailabilityIcon className="icon icon--xl" availability={stockItem.availability} invoiced={stockItem.invoiced} />
                </Box>
            </StyledTooltip>
        )
    }

    const renderVoucherInfo = () => {
        if (!stockItem.invoiced) {
            return
        }

        return (
            <>
                <StyledIcon name="vehicle-front" />
                <InfoList items={voucherInfoItems ?? []} />
            </>
        )
    }

    const renderStockInfo = () => {
        return (
            <>
                <StyledIcon name="stock" />
                <InfoList items={stockInfoItems ?? []} />
            </>
        )
    }

    const renderVoucherButton = () => {
        const voucher = stockItem.vouchers?.[0]

        if (!stockItem.invoiced || !voucher || !permittedOperations?.includes("showVoucher")) {
            return
        }

        return (
            <Button onClick={() => handleVoucherClick(voucher)} sx={{ marginLeft: "auto" }} size="small" variant="contained">
                {translateText(2008)}
            </Button>
        )
    }

    return (
        <StyledStack direction="row">
            {renderStatus()}
            {renderVoucherInfo()}
            {renderStockInfo()}
            {renderVoucherButton()}
        </StyledStack>
    )
}

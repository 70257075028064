import { MouseEvent, useCallback, useMemo, useState } from "react"
import { Checkbox, MenuItem, MenuList, Popover, Stack } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { GraduatedPrices } from "./GraduatedPrices"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Watchlist } from "../Watchlist"
import { IconButton } from "../IconButton"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"

export function RightActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const article = useDefaultArticleItemState((x) => x.article)
    const quantity = useDefaultArticleItemState((x) => x.quantity)
    const hasWatchList = useDefaultArticleItemState((x) => x.options.hasWatchList)
    const { isArticleSelected, toggleSelectedArticle } = useDefaultArticleItemState((x) => x.selection)
    const [moreMenuEl, setMoreMenuEl] = useState<HTMLButtonElement>()
    const {
        isInWatchList,
        watchLists,
        createWatchList,
        toggleWatchList,
        isToggling,
        isCreating,
        isLoading: isWatchlistLoading,
    } = useDefaultArticleItemState((x) => x.watchList)

    const buttonList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_ICONS" && !!handler), [articleActions])
    const moreList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_MENU" && !!handler), [articleActions])

    const handleClickMore = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setMoreMenuEl(e.currentTarget)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleFurtherOptions)
    }, [])

    const handleCloseMore = useCallback(() => {
        setMoreMenuEl(undefined)
    }, [])

    const handleArticleCheckBoxEvent = () => {
        toggleSelectedArticle({
            ...article,
            quantity,
        })
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleCheckbox, !isArticleSelected(article))
    }

    return (
        <Stack direction="row" justifyContent="end" spacing={1}>
            <GraduatedPrices />
            {buttonList.map(({ id, text, tooltip: title, icon, handler, isHighlight }) => (
                <Tooltip key={id} title={title ?? text}>
                    <IconButton onClick={handler}>
                        <Icon name={icon} color={isHighlight ? "primary" : undefined} />
                    </IconButton>
                </Tooltip>
            ))}

            {hasWatchList && (
                <Watchlist
                    onToggleWatchList={toggleWatchList}
                    onCreateWatchList={createWatchList}
                    watchLists={watchLists ?? []}
                    isInWatchList={isInWatchList}
                    isToggling={isToggling}
                    isCreating={isCreating}
                    isLoading={isWatchlistLoading}
                />
            )}

            {!!moreList.length && (
                <>
                    <IconButton onClick={handleClickMore}>
                        <Icon name="ellipsis" />
                    </IconButton>
                    <Popover
                        anchorEl={moreMenuEl}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        open={!!moreMenuEl}
                        onClose={handleCloseMore}
                    >
                        <MenuList variant="menu">
                            {moreList.map(({ id, text, icon, handler, isHighlight }) => (
                                <MenuItem key={id} onClick={mergeHandler(handleCloseMore, handler)}>
                                    <Stack direction="row" gap={1}>
                                        <Icon name={icon} color={isHighlight ? "primary" : undefined} />
                                        <Typography variant="body2" color={isHighlight ? "primary" : undefined}>
                                            {text}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Popover>
                </>
            )}

            <Checkbox size="small" sx={{ padding: 0 }} checked={isArticleSelected(article)} onChange={handleArticleCheckBoxEvent} />
        </Stack>
    )
}

function mergeHandler(...handler: (() => void)[]) {
    return () => handler.forEach((handle) => handle())
}

import {
    Box,
    DividerProps,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography as MuiTypography,
    styled,
    TypographyProps,
} from "@mui/material"
import { useLocalization } from "@tm/localization"
import { CarModelDetails, ConstructionYear, RegistrationNoType, Vehicle } from "@tm/models"
import { getVehicleRegistrationNoLabelTextIds } from "@tm/utils"
import { useState } from "react"
import { Icon } from "../../generics/Icons"
import { LinkButton } from "../../generics/button"
import { Divider as GenericDivider } from "../../generics/divider"
import { LicensePlate } from "../LicensePlate"
import { VehicleImage } from "../VehicleImage"

const Accordion = styled(MuiAccordion)(() => ({
    borderRadius: "3px",
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    backgroundColor: "#fbfdff",
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<Icon name="down" size="24px" />} {...props} />)(() => ({
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
        justifyContent: "space-between",
        "&.Mui-expanded": {
            margin: "12px 0",
        },
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginTop: "-15px",
}))

const Typography = styled((props: TypographyProps) => <MuiTypography variant="h4" margin="0 5px" {...props} />)(() => ({
    opacity: 0.87,
}))
const HeaderText = styled((props: TypographyProps) => <Typography textTransform="uppercase" {...props} />)(() => ({
    opacity: 0.54,
    fontSize: "10px",
    marginBottom: "8px",
}))

const Divider = styled((props: DividerProps) => <GenericDivider orientation="vertical" flexItem {...props} />)(() => ({
    margin: "0 5px",
    borderWidth: "thin",
}))

const PLACEHOLDER = "________________________"

type ButtonProps = {
    to: string
    handleClick: () => void
}

export type VehicleSelectedProps = {
    vehicle?: Vehicle
    modelDetails: CarModelDetails
    useCountryCodeToLicensePlate: (countryCode?: string) => { plateCode: string }
    useRegNoAndType: () => { regNoType?: RegistrationNoType; regNo?: string }
    replaceButton: ButtonProps
    detailsButton: ButtonProps
}

export const VehicleSelected = (props: VehicleSelectedProps) => {
    const { vehicle, modelDetails, useCountryCodeToLicensePlate, useRegNoAndType, replaceButton, detailsButton } = props

    const [expanded, setExpanded] = useState(false)
    const { translate, date, number } = useLocalization()
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)
    const { regNoType, regNo } = useRegNoAndType()

    if (!vehicle) {
        return null
    }

    const { enginePowerKw, enginePowerHp, constructionYearFrom, constructionYearTo, engineCapacityCcm } = modelDetails

    const shortenedModelThumbnail = vehicle.modelThumbnail ? vehicle.modelThumbnail.replace(/https?:/, "") : ""
    const shortenedModelSeriesThumbnail = vehicle.modelSeriesThumbnail ? vehicle.modelSeriesThumbnail.replace(/https?:/, "") : ""

    let regNoLabel
    if (vehicle?.registrationNo) {
        const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(regNoType)
        regNoLabel = itemLabelTextIds?.labelShort ?? itemLabelTextIds?.label
    }

    const handleClickReplace = (e: React.MouseEvent<HTMLElement>) => {
        replaceButton.handleClick()
    }
    const handleClickDetails = (e: React.MouseEvent<HTMLElement>) => {
        detailsButton.handleClick()
    }

    return (
        <Accordion onChange={(_, exp) => setExpanded(exp)}>
            <AccordionSummary>
                <>
                    <Box sx={{ display: "flex", flexDirection: "horizontal", alignItems: "center" }}>
                        <Icon name="car" size="20px" sx={{ margin: "0 9px 0 0" }} />
                        <Typography>
                            {vehicle.manufacturer || "-"} {removeBrackets(vehicle.modelSeries) || "-"} {removeBrackets(vehicle.model) || "-"}
                        </Typography>
                        {!expanded && (
                            <>
                                <Divider />
                                {vehicle.plateId && <LicensePlate style={{ margin: "0 5px" }} countryCode={plateCode} value={vehicle.plateId} />}
                                <Typography>
                                    {translate(329)}/{translate(330)}: {enginePowerKw || "-"}/{enginePowerHp || "-"}
                                </Typography>
                                <Divider />
                                <Typography>
                                    {translate(331)}: {engineCapacityCcm || "-"}
                                </Typography>
                                <Divider />
                                <Typography>
                                    {translate(1489)}: {vehicle.engineCode || "-"}
                                </Typography>
                                <Divider />
                                <Typography>
                                    {translate(1487)}: {constructionYearToString(constructionYearFrom)} -{" "}
                                    {constructionYearToString(constructionYearTo)}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box sx={{ marginRight: "2px" }}>
                        <LinkButton
                            startIcon={<Icon name="replace" />}
                            onClick={handleClickReplace}
                            sx={{ marginRight: "8px" }}
                            to={replaceButton.to}
                        >
                            {translate(1507)}
                        </LinkButton>
                        <LinkButton
                            startIcon={<Icon name="details" />}
                            onClick={handleClickDetails}
                            sx={{ marginRight: "8px" }}
                            to={detailsButton.to}
                        >
                            {translate(116)}
                        </LinkButton>
                    </Box>
                </>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column" alignItems="center" marginRight="15px">
                    <VehicleImage
                        modelImage={shortenedModelThumbnail}
                        modelSeriesImage={shortenedModelSeriesThumbnail}
                        vehicleType={vehicle.vehicleType}
                        width="120px"
                        style={{ margin: "10px 0" }}
                    />
                    {vehicle.plateId && (
                        <LicensePlate style={{ height: "42px", margin: "10px 5px" }} countryCode={plateCode} value={vehicle.plateId} />
                    )}
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" height="42px" margin="10px 0">
                        <Divider />
                        <Box>
                            <HeaderText>{translate(124)}</HeaderText>
                            <Typography>{vehicle.initialRegistration ? date(vehicle.initialRegistration, "d") : "___.___.______"}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <HeaderText>{translate(125)}</HeaderText>
                            <Typography>{vehicle.mileAge !== undefined ? number(vehicle.mileAge, 0) : "____.____"}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <HeaderText>{translate(101)}</HeaderText>
                            <Typography>{vehicle.vin || PLACEHOLDER}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <HeaderText>{translate(127)}</HeaderText>
                            <Typography>
                                {constructionYearToString(constructionYearFrom)} - {constructionYearToString(constructionYearTo)}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <HeaderText>
                                {translate(329)}/{translate(330)}
                            </HeaderText>
                            <Typography>
                                {enginePowerKw || "-"}/{enginePowerHp || "-"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" height="42px" margin="10px 0">
                        <Divider />
                        <Box>
                            <HeaderText>{translate(331)}</HeaderText>
                            <Typography>{engineCapacityCcm || "-"}</Typography>
                        </Box>
                        <Divider />
                        <Box>
                            <HeaderText>{translate(105)}</HeaderText>
                            <Typography> {vehicle.engineCode || "-"}</Typography>
                        </Box>
                        <Divider />
                        {regNoLabel && regNo && (
                            <Box>
                                <HeaderText>{translate(regNoLabel) || regNo}</HeaderText>
                                <Typography>{vehicle?.registrationNo || regNo}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }

    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)

    if (!match) {
        return value
    }

    return match[1]
}

function constructionYearToString(data?: ConstructionYear) {
    if (!data) {
        return ""
    }
    return `${data.month}/${data.year}`
}

import { ArticleAction, ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"

type ButtonActionProps = {
    action: ArticleAction
    hideText?: boolean
}

export function ButtonAction({ action: { tooltip, text, handler, icon, isHighlight, id }, hideText }: ButtonActionProps) {
    const handleClickEventWithTma = () => {
        handler()
        switch (id) {
            case "erpAlternatives":
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAlternativeArticle)
                break
            case "details":
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaDetailsButton)
                break
            /// more will be added later
            default:
                break
        }
    }

    let content = (
        <Button
            onClick={handleClickEventWithTma}
            startIcon={<Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            size="small"
            variant="outlined"
            color={isHighlight ? "primary" : undefined}
        >
            {!hideText && text}
        </Button>
    )

    if (tooltip) {
        content = <Tooltip title={tooltip}>{content}</Tooltip>
    }

    return content
}

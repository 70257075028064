import { Box, Collapse, styled } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { TreeNode } from "@tm/models"
import { Breadcrumbs } from "../BreadCrumbs"
import { ListView } from "./ListView"
import { SearchTreeOverview } from "./Overview"
import { Loader } from "../../generics/loader"
import { SearchtreeTip } from "./SearchtreeTip/SearchtreeTip"

const Wrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "withBackgroundColor",
})<{ withBackgroundColor?: boolean }>(({ theme, withBackgroundColor, gridTemplateColumns }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    transform: "translateX(-8px)",
    position: "relative",
    overflowY: "hidden",
    container: "searchtree / inline-size",
    ...(withBackgroundColor && {
        zIndex: 20,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
    }),
    "@container searchtree (max-inline-size: 600px)": {
        ".search-tree .nodes-grid-wrapper": {
            gridTemplateColumns: "1fr",
        },
        ".search-tree .nodes-grid-wrapper .bestseller-wrapper": {
            display: "none",
        },
        ".search-tree .nodes-grid-wrapper .nodes-grid": {
            gridTemplateColumns,
        },
        ".search-tree .overview-grid": {
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
    },
}))

const CollapseWrapper = styled(Collapse)({
    ".MuiCollapse-wrapper": { height: "100%" },
})

type Props = {
    loading?: boolean
    breadCrumbs: TreeNode[]
    tree: TreeNode[]
    selectedNode?: TreeNode
    showBestseller?: boolean
    onNodeClick: (node: TreeNode, fastClick?: boolean) => void
    onBreadCrumbClick: (node: TreeNode) => void
    variant: "full" | "breadcrumbsOnly" | "collapsable"
    layout?: "small" | "default"
    nodeVariant?: "fastClick" | "default"
    isExpanded?: boolean
    withBackgroundColor?: boolean
    applyProductGroups?: (productGroupIds: number[]) => void
    fastCalculation?: (productGroupIds: number[]) => void
    hideFastCalcButton?: boolean
    maxSelectableProductGroups?: number
    className?: string
    hideSecondLevel?: boolean
    hideTipIcon?: boolean
    fastCalculatorDisabled?: boolean
}

export function Searchtree(props: Props) {
    const { loading, hideTipIcon, fastCalculatorDisabled } = props
    const [isActive, setIsActive] = useState<boolean>(false)
    const [viewLoading, setViewLoading] = useState<boolean>(true)

    useEffect(() => {
        if (props.tree.length) {
            setViewLoading(false)
            setIsActive(props.breadCrumbs.length > 0)
        }
    }, [props.tree, props.breadCrumbs])

    const onOverviewNodeSelect = (node: TreeNode, fastClick?: boolean) => {
        setViewLoading(true)
        setIsActive(true)
        props.onNodeClick(node, fastClick)
    }

    const isSecondLevel = useMemo(() => {
        return props.breadCrumbs.length > 0
    }, [props.breadCrumbs])

    const gridTemplateColumns = "repeat(2, minmax(0, 1fr))"

    return (
        <Wrapper
            withBackgroundColor={props.withBackgroundColor}
            gridTemplateColumns={gridTemplateColumns}
            className={`${props.className ?? ""} search-tree`}
        >
            {(isActive || props.variant === "collapsable") && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }} pr={2}>
                    <Box width="100%" py="5px">
                        <Breadcrumbs
                            onBreadcrumbClick={props.onBreadCrumbClick}
                            breadCrumbs={props.breadCrumbs}
                            isLoading={loading}
                            disabled={!isActive && viewLoading}
                        />
                    </Box>
                    {props.isExpanded && !hideTipIcon && <SearchtreeTip isSecondLevel={isSecondLevel} />}
                </Box>
            )}
            <CollapseWrapper in={props?.isExpanded || props.variant === "full"} timeout="auto" unmountOnExit>
                {(loading || viewLoading) && !isActive ? (
                    <Box display="flex" alignItems="center" width="100%" minHeight="200px">
                        <Loader />
                    </Box>
                ) : (
                    <>
                        {isActive ? (
                            <ListView
                                variant={props.layout || "default"}
                                selectedNode={props.selectedNode}
                                bestseller={props.showBestseller}
                                onNodeClick={props.onNodeClick}
                                isLoading={loading || viewLoading}
                                applyProductGroups={props.applyProductGroups}
                                fastCalculation={props.fastCalculation}
                                hideFastCalcButton={props.hideFastCalcButton}
                                maxSelectableProductGroups={props.maxSelectableProductGroups}
                                fastCalculatorDisabled={fastCalculatorDisabled}
                            />
                        ) : (
                            <SearchTreeOverview
                                variant={props.layout || "default"}
                                initialTree={props.tree}
                                onNodeClick={onOverviewNodeSelect}
                                hideSecondLevel={props.hideSecondLevel}
                                nodeVariant={props.nodeVariant}
                            />
                        )}
                    </>
                )}
            </CollapseWrapper>
        </Wrapper>
    )
}

import { ArticleAction, ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { Icon } from "../../../generics/Icons"
import { IconButton } from "../../../generics/button"
import { Tooltip } from "../../../generics/tooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ImsIcon } from "../ImsIcon"

type IconActionProps = {
    action: ArticleAction
}

export function IconAction({ action: { tooltip, text, handler, icon, isHighlight, id } }: IconActionProps) {
    const imsIcon = useDefaultArticleItemState((x) => x.imsIcon)

    const handleClickEventWithTma = () => {
        handler()
        switch (id) {
            case "accessories":
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAccessoryList)
                break
            /// more will be added later
            default:
                break
        }
    }

    return (
        <Tooltip title={tooltip ?? text}>
            <IconButton onClick={handleClickEventWithTma}>
                {icon === "trader-icon" && imsIcon ? <ImsIcon /> : <Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            </IconButton>
        </Tooltip>
    )
}

import { PriceType } from "@tm/models"
import { useMemo } from "react"
import { Box, Skeleton, Stack } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ErpIcons } from "../ErpIcons"
import { Availabilities } from "./Availabilities"
import { ExtendedDelivery } from "./ExtendedDelivery"
import { HTMLInformation } from "./HTMLInformation"
import { OtherPrices } from "./OtherPrices"
import { PriceUnit } from "./PriceUnit"
import { ErpPriceWithAdditionalInfo, Prices } from "./Prices"
import { Costs } from "./Costs"
import { useArticleItemState } from "../ContextProviderStates"

export function DefaultArticleErpContainer() {
    const { requestErpInfo } = useDefaultArticleItemState((x) => x.article)
    const showPurchasePrice = useDefaultArticleItemState((x) => x.options.showPurchasePrice)

    if (!requestErpInfo) {
        return null
    }

    return <ErpContainer showPurchasePrice={showPurchasePrice} showExtendedDelivery />
}

export function OePartErpContainer() {
    const showPurchasePrice = useDefaultArticleItemState((x) => x.options.showPurchasePrice)
    return <ErpContainer showPurchasePrice={showPurchasePrice} />
}

type ErpContainerProps = {
    showPurchasePrice?: boolean
    showExtendedDelivery?: boolean
}

function ErpContainer({ showPurchasePrice, showExtendedDelivery }: ErpContainerProps) {
    /** @todo Check if we really need these two parameters anymore --> NEXT-26321 */
    // const { hidePurchasePriceIfReplacementArticlesAvailable, priceOrder } = Morpheus.getParams<ErpBundleParams>("erp")

    const articleErpInfos = useArticleItemState((x) => x.articleErpInfos)
    const defaultArticleErpInfo = articleErpInfos?.default
    const defaultErpInfo = defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo.response : undefined

    const mainPricesWithAdditionalInfo = useMemo(
        () =>
            defaultErpInfo?.prices
                ?.filter((x) => x.type)
                .map<ErpPriceWithAdditionalInfo>((x) => ({ ...x, onlyShowInfo: x.type === PriceType.Purchase && !showPurchasePrice })) ?? [],
        [defaultErpInfo?.prices, showPurchasePrice]
    )

    const otherPrices = useMemo(() => defaultErpInfo?.prices?.filter((price) => !price.type && !!price.description) ?? [], [defaultErpInfo?.prices])

    if (!defaultArticleErpInfo) {
        return <Box height={32} />
    }

    if (defaultArticleErpInfo?.state === "loading") {
        return (
            <Stack direction="row" justifyContent="space-between">
                <Skeleton variant="rounded" width={100} height={32} />
                <Stack spacing="2px">
                    <Skeleton variant="rounded" width={80} height={15} />
                    <Skeleton variant="rounded" width={80} height={15} />
                </Stack>
            </Stack>
        )
    }

    // Commented out code belongs to line 22 and stays until todo is done
    /* const mainPrices = getOrderedPrices(prices.filter((price) => {
        // filter out prices without type
        if (!price.type) {
            return false
        }

        if (price.type === PriceType.Purchase) {
            // filter out purchase price if not enabled
            if (!showPurchasePrice) {
                return false
            }

            // // when configured: filter out purchase price if replacement articles are available
            // if (hidePurchasePriceIfReplacementArticlesAvailable && erpInfo.hasReplacementArticles) {
            //     return false
            // }
        }

        return true
    }), priceOrder) */

    const erpIcons = <ErpIcons position="erpInfo" />

    return (
        <Stack gap={1}>
            <Stack display="grid" gridTemplateColumns="auto auto" columnGap={1}>
                <Stack direction="row">
                    <Availabilities />
                </Stack>
                {!!mainPricesWithAdditionalInfo.length && (
                    <Stack>
                        <Prices prices={mainPricesWithAdditionalInfo} />
                        <PriceUnit erpInfo={defaultErpInfo} />
                    </Stack>
                )}
            </Stack>
            {!!otherPrices.length && (
                <Box alignSelf="flex-end">
                    <OtherPrices prices={otherPrices} />
                </Box>
            )}
            {!!defaultErpInfo?.costs?.length && <Costs costs={defaultErpInfo.costs} />}
            {showExtendedDelivery && <ExtendedDelivery />}
            {erpIcons && (
                <Stack direction="row" flexWrap="wrap" justifyContent="flex-end" alignItems="center" spacing={0.5}>
                    {erpIcons}
                </Stack>
            )}
            {defaultErpInfo?.additionalInformationExtended && (
                <HTMLInformation additionalInformationExtended={defaultErpInfo?.additionalInformationExtended} />
            )}
        </Stack>
    )
}

// function getOrderedPrices(pricesToSort: ErpPrice[], priceOrder: (string | number)[][]) {
//     if (priceOrder && priceOrder[0].length > 0) {
//         return sortBy(pricesToSort, (x) => priceOrder[0].indexOf(x.type))
//     }

//     return pricesToSort
// }
